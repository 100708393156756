$(document).foundation();

$(document).ready(function(){
	$('.main-carousel').slick({
		arrows: false,
		dots: true,
		adaptiveHeight: true
	});

	$('.slider3').slick({
		arrows: false,
		dots: false,
		adaptiveHeight: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1025,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				}
			}
		]
	});
});